import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { options } from '@options';

@Pipe({
    name: 'price',
})
export class PricePipe implements PipeTransform {
    private translateService = inject(TranslateService);

    transform(value: number | null): string {
        if (value === null) {
            return this.translateService.instant(`currency.${options.currency}`, { value: '-' });
        }
        const [intPart, floatPart] = Number(value / 100)
            .toFixed(2)
            .split('.');

        const price = `${this.addSpaceSeparators(intPart)},${floatPart}`;
        return this.translateService.instant(`currency.${options.currency}`, { value: price });
    }

    private addSpaceSeparators(x: string) {
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
}
